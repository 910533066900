import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import { Hero, HeroText } from "../components/Hero"
import { LeftContainer, RightContainer } from "../components/Templates"

import { Contact } from "../components/contact-us"
import SEO from "../components/seo"

const APropos = ({ data }) => (
  <Layout>
    <SEO title="A Propos" />

    <Hero fluid={data.hero.edges[0].node.fluid}>
      <HeroText text="A Propos"/>
    </Hero>

    <RightContainer
      imageSrc="Saadify/undraw_about_me_wa29.png"
      header="Qui sommes-nous"
      text = {[ "Entrepreneur et professionnel de l'informatique depuis près d'une dizaine d'années dans le développement web ainsi que dans l'hébergement d'infrastructures.",
                "Fort de l'expertise accumulée durant ces années nous avons décidés de promouvoir nos services afin d'aider et d'accompagner les divers professionnels qui souhaitent profiter des opportunités présentent sur le web."
               ]}
    />

    <LeftContainer
      imageSrc="Saadify/undraw_building_websites_i78t.png"
      header="Notre activité"
      text = {[ "Nous vous proposons divers services pour accompagner votre transition numérique que ce soit la réalisation de sites internet, la formation ou la publicité en ligne.",
                "Notre souhait de vous satisfaire et de créer un partenariat solide à long terme nous pousse à nous dépasser chaque jour pour répondre à vos besoins et développer vos activités autant que possible."
               ]}
    />

    <RightContainer
      imageSrc="Saadify/SaadDIF_Certification-fondamentaux-marketing-numerique-google.png"
      header="Professionnel certifié"
      text={[ "Prestations réalisées par une professionnel certifié Google Web Marketing",
              "Internet et les réseaux sociaux sont aujourd'hui devenu des plateformes incontournable pour promouvoir une activité, nous vous proposons de vous accompagner et de vous former sur ces nouveaux outils afin de maximiser votre visibilité en ligne et d'atteindre de nouveaux clients."
            ]}
    />


    <Contact
      contactButton = {[
        "/contact",
        "Contact"
      ]}
    />
  </Layout>
)

export default APropos

export const pageQuery = graphql`
  query {
    hero: allImageSharp(filter: { original: { src: { regex: "/computer-1245714_1280/" } } }) {
      edges {
        node {
          id
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
